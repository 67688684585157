export const CacheKeys = {
  // === ANCESTORS ===
  Ancestors: 'ancestors',
  // === COMBO ===
  RatingHistory: 'ratingHistory',
  ComboInfo: 'comboInfo',
  ComboDataByCoverageId: 'comboDataByCoverageId',
  // === COVERAGE ===
  CoverageDataByComboID: 'coverageDataByComboID',
  // === POLICY ===
  PolicyListByCoverageId: 'policyListByCoverageId',
  PolicySummaryDetails: 'policySummaryDetails',
  PolicyPrimaryName: 'policyPrimaryName',
  PolicyPremiums: 'policyPremiums',
  PolicyExposure: 'policyExposure',
  PolicyEmployersLocations: 'policyEmployersLocations',
  PolicyCancellationsReinstatements: 'policyCancellationsReinstatements',
  PolicyEndorsements: 'policyEndorsements',
  PolicyUnitStats: 'policyUnitStats',
  PolicyErrors: 'policyErrors',
  MovePoliciesToNewCoverage: 'movePoliciesToNewCoverage',
  // === UNIT STAT ===
  UnitStatDetails: 'unitStatDetails',
  UnitStatPolicyInfo: 'unitStatPolicyInfo',
  UnitStatPolicyConditions: 'unitStatPolicyConditions',
  UnitStatPolicyType: 'unitStatPolicyType',
  UnitStatDeductible: 'unitStatDeductible',
  UnitStatExposures: 'unitStatExposures',
  UnitStatLossRecords: 'unitStatLossRecords',
  UnitStatErrors: 'unitStatErrors',
  // === RATING ===
  RatingDetails: 'ratingDetails',
  RatingInfoByComboId: 'ratingInfoByComboId',
  // === CLAIM ===
  ClaimDetails: 'claimDetails',
  // === WORK QUEUE ===
  OpenWorkQueueItems: 'openWorkQueueItems',
  ClosedWorkQueueItems: 'closedWorkQueueItems',
  GetMyWorkQueueDetails: 'getMyWorkQueueDetails',
  UnmatchedTransaction: 'unmatchedTransaction',
  CarrierErrorNotifications: 'carrierErrorNotifications',
}
