import { ConfirmMatchDTO } from '../models/ConfirmMatchDTO'
import { GenericSuccessReponse } from '../models/GenericSuccessReponse'
import { CarrierErrorNotification } from '../models/WorkItemDetails/CarrierErrorNotification'
import { MatchSuccessResponse } from '../models/WorkItemDetails/MatchSuccessResponse'
import { MatchingCoverageResponse } from '../models/WorkItemDetails/MatchingCoverageResponse'
import { SuggestedMatchModel } from '../models/WorkItemDetails/SuggestedMatchModel'
import { UnmatchedTransactionModel } from '../models/WorkItemDetails/UnmatchedTransactionModel'
import WorkItemDetailsModel from '../models/WorkItemDetails/WorkItemDetailsModel'
import { WorkItemReassignUserModel } from '../models/WorkItemDetails/WorkItemReassignUser'
import api, { ApiResponse } from '../utils/apiService'
import { Endpoints } from '../utils/endpoint'

export const getWorkItemDetails = async (workItemId: string) => {
  const response: ApiResponse<WorkItemDetailsModel> = await api.get(
    `${Endpoints.WorkQueue}/workItem/details/${workItemId}`
  )
  return response.data
}

export const getUnmatchedTransaction = async (workItemId: string) => {
  const response: ApiResponse<UnmatchedTransactionModel> = await api.get(
    `${Endpoints.WorkQueue}/workItem/unmatchedtransaction/${workItemId}`
  )
  return response.data
}

export const getSuggestedMatches = async (workItemId: string) => {
  const response: ApiResponse<SuggestedMatchModel[]> = await api.get(
    `${Endpoints.WorkQueue}/workItem/suggestedMatches/${workItemId}`
  )
  return response.data
}

export const getCarrierErrorNotifications = async (workItemId: string) => {
  const response: ApiResponse<CarrierErrorNotification> = await api.get(
    `${Endpoints.WorkQueue}/workItem/carrierErrorNotifications/${workItemId}`
  )
  return response.data
}

export const resolveUSRCarrierError = async (
  userAccepted: boolean,
  workItemId: string,
  responseText?: string
): Promise<number> => {
  const response: ApiResponse<number> = await api.post(
    `${Endpoints.WorkQueue}/workItem/carrierErrorNotifications/${workItemId}`,
    {
      userAccepted: userAccepted,
      workItemGuid: workItemId,
      responseText: responseText,
      entityType: 'unitstat',
    }
  )
  return response.data
}

export const resolvePolicyCarrierError = async (
  userAccepted: boolean,
  workItemId: string,
  responseText?: string
): Promise<number> => {
  const response: ApiResponse<number> = await api.post(
    `${Endpoints.WorkQueue}/workItem/carrierErrorNotifications/${workItemId}`,
    {
      userAccepted: userAccepted,
      workItemGuid: workItemId,
      responseText: responseText,
      entityType: 'policy',
    }
  )
  return response.data
}
export const matchPolicyToCoverage = async (workItemId: string, req: ConfirmMatchDTO) => {
  const response: ApiResponse<MatchSuccessResponse> = await api.post(
    `${Endpoints.WorkQueue}/workItem/${workItemId}/match`,
    {
      coverageId: req.coverageId,
      comboId: req.comboId,
    }
  )
  return response.data
}

export const findMatchingCoverage = async (coverageID: string) => {
  const response: ApiResponse<MatchingCoverageResponse> = await api.get(
    `${Endpoints.WorkQueue}/workItem/findMatchingCoverage/${coverageID}`
  )
  return response.data
}

export const findMatchingCombo = async (comboID: string) => {
  const response: ApiResponse<MatchingCoverageResponse> = await api.get(
    `${Endpoints.WorkQueue}/workItem/findMatchingCombo/${comboID}`
  )
  return response.data
}

//================================================================================================
// this dfucntion is used to manually close a work item
export const workItemManualClose = async (workItemId: string, responseText?: string): Promise<number> => {
  const response: ApiResponse<number> = await api.post(`${Endpoints.WorkQueue}/workItem/ManualClose`, {
    workItemId: workItemId,
    responseText: responseText,
  })

  return response.status
}

//================================================================================================
// this function pull all users that can be reassigned to a work item
export const workItemGetReassignUsers = async (): Promise<WorkItemReassignUserModel[]> => {
  try {
    const response: ApiResponse<WorkItemReassignUserModel[]> = await api.get(
      `${Endpoints.WorkQueue}/workItem/Reassign/GetUsers`
    )
    return response.data
  } catch {
    return []
  }
}

//================================================================================================
// this function pull all users that can be reassigned to a work item
export const workItemReassignUser = async (workItemId: string, userEmail: string): Promise<GenericSuccessReponse> => {
  const response: ApiResponse<GenericSuccessReponse> = await api.patch(`${Endpoints.WorkQueue}/workItem/Reassign`, {
    workItemId: workItemId,
    newUserEmail: userEmail,
  })
  return response.data
}

//================================================================================================
// this function pull all users that can be reassigned multiple work items to single person
export const workItemsReassignUser = async (
  workItemIds: string[],
  userEmail: string
): Promise<GenericSuccessReponse> => {
  const response: ApiResponse<GenericSuccessReponse> = await api.patch(`${Endpoints.WorkQueue}/workItems/Reassign`, {
    workItemIds: workItemIds,
    newUserEmail: userEmail,
  })
  return response.data
}

//================================================================================================
// this dfucntion is used to manually close multiple work items
export const workItemsManualClose = async (workItemIds: string[], responseText?: string): Promise<number> => {
  const response: ApiResponse<number> = await api.post(`${Endpoints.WorkQueue}/workItems/ManualClose`, {
    workItemIds: workItemIds,
    responseText: responseText,
  })

  return response.status
}
