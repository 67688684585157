import { Folder } from '@mui/icons-material'
import BeachAccessIcon from '@mui/icons-material/BeachAccess'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import { Box, Stack } from '@mui/material'
import { FieldGroup, PhoenixBaseCard, PhoenixLink, StatusBadge } from 'componix'
import { UUID } from 'crypto'
import { PolicyDetailsResponseModel } from '../../../models/PolicyDetails/PolicyDetailsResponseModel'

interface PolicyInfoGutterCardProps {
  policy: PolicyDetailsResponseModel | undefined
  guid: UUID
  isLoading: boolean
}

const PolicyInfoGutterCard = ({ policy, guid, isLoading }: PolicyInfoGutterCardProps) => {
  return (
    <Box sx={{ marginTop: '16px' }}>
      <PhoenixBaseCard cardTitle="Policy Information">
        <Stack sx={{ margin: '16px' }} data-testid="loaded">
          <Box width={'fit-content'} marginBottom={'8px'}>
            <StatusBadge description={policy?.summary.status} isLoading={isLoading} />
          </Box>
          <FieldGroup
            label="Carrier ID"
            value={policy?.summary.carrierId}
            icon={<BeachAccessIcon />}
            isLoading={isLoading}
          />
          <FieldGroup
            label="Policy Number"
            value={<PhoenixLink to={`/policies/${guid}`} value={policy?.summary?.policyNumber ?? ''} />}
            icon={<Folder />}
            isLoading={isLoading}
          />
          <FieldGroup
            label="Policy Effective Date"
            value={policy?.summary.effectiveDate}
            icon={<CalendarTodayIcon />}
            isLoading={isLoading}
          />
          <FieldGroup
            label="Policy Expiration Date"
            value={policy?.summary.expirationDate}
            icon={<CalendarTodayIcon />}
            isLoading={isLoading}
          />
          <FieldGroup
            label="State Effective Date"
            value={policy?.information.stateEffectiveDate}
            icon={<CalendarTodayIcon />}
            isLoading={isLoading}
          />
        </Stack>
      </PhoenixBaseCard>
    </Box>
  )
}

export default PolicyInfoGutterCard
