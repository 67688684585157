import ChevronRightFilled from '@mui/icons-material/ChevronRight'
import { Button, Dialog } from '@mui/material'
import { PhoenixBaseCard, PhoenixBaseTable } from 'componix'
import { MRT_ColumnDef } from 'material-react-table'
import * as React from 'react'

interface AttachmentsModalProps {
  isOpen: boolean
  onCancel: () => void
  attachments: AttachmentsProps[]
}

interface AttachmentsProps {
  name: string
  path: string
}

export default function AttachmentsModal({ isOpen, onCancel, attachments }: AttachmentsModalProps) {
  const handleClose = () => {
    onCancel()
  }

  const columns: MRT_ColumnDef<AttachmentsProps>[] = [
    {
      accessorKey: 'name',
      header: 'File Name',
      size: 250,
    },
    {
      accessorKey: 'path',
      header: '',
      size: 0,
      grow: false,
      /* eslint-disable  @typescript-eslint/no-explicit-any */
      Cell: ({ cell }: any) => {
        return (
          cell.getValue() && (
            <Button
              variant="contained"
              color="primary"
              size="small"
              endIcon={<ChevronRightFilled />}
              href={cell.getValue()}
              target="_blank"
            >
              View
            </Button>
          )
        )
      },
    },
  ]
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth={'xs'}
      data-testid={'attachments-modal'}
      sx={{
        '& .MuiDialog-container > .MuiPaper-root': {
          borderRadius: '8px',
        },
      }}
    >
      <PhoenixBaseCard cardTitle="Attachments" closable onClose={handleClose}>
        <PhoenixBaseTable
          columns={columns}
          data={attachments}
          overrides={{
            muiTableHeadRowProps: {
              sx: { display: 'none' },
            },
          }}
        />
      </PhoenixBaseCard>
    </Dialog>
  )
}
