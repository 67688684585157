import { Autocomplete, Button, CircularProgress, Dialog, DialogActions, DialogContent, TextField } from '@mui/material'
import { Fragment, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import WorkItemReassignUserModel from '../../models/WorkItemDetails/WorkItemReassignUser'
import { workItemGetReassignUsers } from '../../services/workItemService'
import PhoenixModalHeader from './PhoenixModalHeader'

interface IFormInput {
  userEmail: string
}

interface WorkItemReassignModalProps {
  isOpen: boolean
  onCancel: () => void
  onReassign: (userEmail: string) => void
}

const WorkItemReassignModal = ({ isOpen, onCancel, onReassign }: WorkItemReassignModalProps) => {
  const { handleSubmit, reset, formState } = useForm({
    defaultValues: {
      userEmail: '',
    },
  })

  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState<readonly WorkItemReassignUserModel[]>([])
  const [loading, setLoading] = useState(false)
  const [person, setPerson] = useState<WorkItemReassignUserModel | null>(null)

  let theUsers: WorkItemReassignUserModel[] = []

  const handleOpen = () => {
    setOpen(true)
    ;(async () => {
      setLoading(true)
      workItemGetReassignUsers()
        .then((data: WorkItemReassignUserModel[]) => {
          if (data) {
            theUsers = data
          }
        })
        .catch(() => {
          console.log('error loading users')
        })
        .finally(() => {
          setLoading(false)
          setOptions([...theUsers])
        })
    })()
  }

  /* istanbul ignore next */
  const handleClose = () => {
    setOpen(false)
    setOptions([])
  }

  const onSubmit: SubmitHandler<IFormInput> = () => {
    if (person?.email) {
      onReassign(person.email)
    }
    reset()
    onCancel()
  }

  /* istanbul ignore next */
  const closeAndClear = () => {
    onCancel()
    reset()
  }

  return (
    <Dialog fullWidth open={isOpen} maxWidth={'md'} data-testid={'reassign-modal'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PhoenixModalHeader title={'Reassign Work Item(s)'} isErrorColor={false} handleClose={closeAndClear} />
        <DialogContent>
          <Autocomplete
            data-testid={'userautocomplete'}
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
            isOptionEqualToValue={(option, value) => option.name === value.email}
            getOptionLabel={(option) => option.name}
            options={options}
            loading={loading}
            onChange={(event, value) => {
              setPerson(value)
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Assign to"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <Fragment>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </Fragment>
                  ),
                }}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button data-testid="cancel-manual-btn" size={'small'} variant="text" onClick={closeAndClear}>
            Cancel
          </Button>
          <Button
            disabled={person == null || formState.isSubmitting}
            size={'small'}
            variant="contained"
            color="primary"
            type="submit"
          >
            {formState.isSubmitting ? 'Submitting...' : 'REASSIGN'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default WorkItemReassignModal
