import { CalendarToday, DescriptionOutlined, Person } from '@mui/icons-material'
import { Box, Stack, Typography } from '@mui/material'
import { FieldGroup, PhoenixBaseCard, StatusBadge } from 'componix'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAlertContext } from '../../contexts/AlertContext'
import WorkItemDetailsModel from '../../models/WorkItemDetails/WorkItemDetailsModel'
import { workItemManualClose, workItemReassignUser } from '../../services/workItemService'
import { AlertSeverity } from '../AlertNotification/AlertNotification'
import ManualCloseModal from '../Modals/ManualCloseModal'
import WorkItemReassignModal from '../Modals/WorkItemReassignModal'

interface WorkItemDetailsCardProps {
  data?: WorkItemDetailsModel | null
  isLoading: boolean
}

//================================================================================================
export const WorkItemDetailsCard = ({ isLoading, data }: WorkItemDetailsCardProps) => {
  const { setDetails: setAlertDetails } = useAlertContext()
  const [workItemStatus, setWorkItemStatus] = useState<string>()
  const statusTypes = ['Open', 'Closed', 'Manually Closed']
  const [isManualCloseModalOpen, setIsManualCloseModalOpen] = useState(false)
  const [isWorkItemReassignModalOpen, setIsWorkItemReassignModal] = useState(false)
  const navigate = useNavigate()

  //================================================================================================
  useEffect(() => {
    const status = data?.workItemStatus && statusTypes.find((item) => item.startsWith(data?.workItemStatus))
    setWorkItemStatus(status)
    //eslint-disable-next-line
  }, [data?.workItemStatus])

  //================================================================================================
  /* istanbul ignore next */
  const handleReassign = async (userEmail: string) => {
    await workItemReassignUser(data!.workQueue_WorkItemID, userEmail)
      .then(() => {
        setAlertDetails({ message: 'Work Item reassigned successfully.', severity: AlertSeverity.SUCCESS })
        navigate('/', { replace: true })
      })
      .catch(() => setAlertDetails({ message: 'Work Item Failed To Reassign.', severity: AlertSeverity.ERROR }))
  }

  //================================================================================================
  const handleonManualClose = async (responseText: string) => {
    await workItemManualClose(data!.workQueue_WorkItemID, responseText)
      .then(() => {
        setAlertDetails({ message: 'Work Item closed successfully.', severity: AlertSeverity.SUCCESS })
        navigate('/', { replace: true })
      })
      .catch(() =>
        setAlertDetails({ message: 'Work Item cannot be closed. Please try again.', severity: AlertSeverity.ERROR })
      )
  }

  //================================================================================================
  /* istanbul ignore next */
  const reassignCloseModal = () => {
    setIsWorkItemReassignModal(false)
  }

  //================================================================================================
  /* istanbul ignore next */
  const closeManualCloseModal = () => {
    setIsManualCloseModalOpen(false)
  }
  //================================================================================================
  /* istanbul ignore next */
  const handleAssignOpen = () => {
    setIsWorkItemReassignModal(true)
  }

  //================================================================================================
  /* istanbul ignore next */
  const handleManuallyClose = () => {
    setIsManualCloseModalOpen(true)
  }

  //================================================================================================
  //TODO: Disable Close Button on Unmatched Policy Pages once the backend is set up
  const kebabMenuItems = [
    { label: 'Reassign', to: '#', onClick: handleAssignOpen },
    { label: 'Manually Close', to: '#', onClick: handleManuallyClose },
  ]

  return (
    <Box sx={{ marginTop: '16px' }}>
      <PhoenixBaseCard
        variantType="Primary"
        cardTitle="Work Item Details"
        contentPadded
        defaultWidth={12}
        kebabable={!isLoading && data?.workItemStatus === 'Open'}
        kebabMenuItems={kebabMenuItems}
      >
        <Stack sx={{ margin: '16px' }}>
          <Box width={'fit-content'} marginBottom={'8px'}>
            <StatusBadge description={workItemStatus} isLoading={isLoading} />
          </Box>
          <FieldGroup
            label={'Work Item Number'}
            icon={<DescriptionOutlined />}
            value={data?.workItemNumber}
            isLoading={isLoading}
          />
          <FieldGroup icon={<Person />} label={'Assigned To'} value={data?.assignedTo} isLoading={isLoading} />
          <FieldGroup icon={<Person />} label={'Assigned By'} value={data?.assignedBy} isLoading={isLoading} />
          <FieldGroup
            label={'Assigned Date'}
            icon={<CalendarToday />}
            value={data?.assignedDate}
            isLoading={isLoading}
          />
          <FieldGroup
            label={'Due Date'}
            icon={<CalendarToday />}
            value={
              data?.dueDate && <Typography {...(data?.isPastDue && { color: 'error' })}>{data?.dueDate}</Typography>
            }
            isLoading={isLoading}
          />
        </Stack>
      </PhoenixBaseCard>
      <ManualCloseModal
        isOpen={isManualCloseModalOpen}
        onCancel={closeManualCloseModal}
        onManualClose={(responseText: string) => handleonManualClose(responseText)}
      />

      <WorkItemReassignModal
        isOpen={isWorkItemReassignModalOpen}
        onCancel={reassignCloseModal}
        onReassign={(userEmail: string) => handleReassign(userEmail)}
      />
    </Box>
  )
}

export default WorkItemDetailsCard
