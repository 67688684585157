import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { AlertSeverity } from '../../components/AlertNotification/AlertNotification'
import { useAlertContext } from '../../contexts/AlertContext'
import { MatchSuccessResponse } from '../../models/WorkItemDetails/MatchSuccessResponse'
import { SuggestedMatchModel } from '../../models/WorkItemDetails/SuggestedMatchModel'
import { matchPolicyToCoverage } from '../../services/workItemService'
import { useBoolean } from '../useBoolean'

export interface IUseMatchPolicyToCoverage {
  isModalOpen: boolean
  openModal: (suggestedTransaction?: SuggestedMatchModel) => void
  closeModal: () => void
  setFoundMatch: (match: SuggestedMatchModel | null) => void
  match: SuggestedMatchModel | null
  successMatch: MatchSuccessResponse | null
  isMatchLoading: boolean
  activeStep: number
  handleConfirmMatch: () => void
}

export const useMatchPolicyToCoverage = (): IUseMatchPolicyToCoverage => {
  const { value: isModalOpen, onTrue, onFalse } = useBoolean()
  const [match, setMatch] = useState<SuggestedMatchModel | null>(null)
  const [successMatch, setSuccessMatch] = useState<MatchSuccessResponse | null>(null)
  const { setDetails: setAlertDetails } = useAlertContext()
  const [activeStep, setActiveStep] = useState(0)
  const { workItemId = '' } = useParams()

  const { mutate: matchFunc, isPending: isMatchLoading } = useMutation({
    mutationKey: ['matchPolicyToCoverage', workItemId, match?.coverage.guid],
    mutationFn: () =>
      matchPolicyToCoverage(workItemId, {
        coverageId: match?.coverage.guid || null,
        comboId: match?.combo.guid || null,
      }),
    onError: () => setAlertDetails({ message: 'Unable to match policy to coverage', severity: AlertSeverity.ERROR }),
    onSuccess: (data) => {
      setActiveStep(1)
      setSuccessMatch(data)
    },
  })

  const handleConfirmMatch = async () => {
    if (!match) return
    matchFunc()
  }

  const openModal = (suggestedTransaction?: SuggestedMatchModel) => {
    if (suggestedTransaction) {
      setMatch(suggestedTransaction)
    } else {
      setMatch(null)
    }
    setActiveStep(0)
    onTrue()
  }

  const closeModal = () => {
    onFalse()
  }

  const setFoundMatch = (match: SuggestedMatchModel | null) => {
    setMatch(match)
  }

  return {
    isModalOpen,
    openModal,
    closeModal,
    setFoundMatch,
    match,
    successMatch,
    isMatchLoading,
    activeStep,
    handleConfirmMatch,
  }
}
