import { Box, Button, Grid } from '@mui/material'
import { ErrorAttachment, PhoenixBaseCard, PhoenixBaseTable, ResponseTimeline } from 'componix'
import { useEffect, useState } from 'react'
import useAttachmentsModal from '../../../hooks/useAttachmentsModal'
import useErrorProcessingModal from '../../../hooks/useErrorProcessingModal'
import { UpdateErrorsModel } from '../../../models/PolicyDetails/UpdateErrorsModel'
import { BlankGenericCarrierError } from '../../../models/WorkItemDetails/CarrierErrorNotification'
import { CarrierErrorNotification } from '../../../models/WorkItemDetails/CarrierErrorNotification'
import WorkItemDetailsModel from '../../../models/WorkItemDetails/WorkItemDetailsModel'
import { getCarrierErrorNotifications, resolveUSRCarrierError } from '../../../services/workItemService'
import AcceptErrorModal from '../../Modals/AcceptErrorModal'
import RejectErrorModal from '../../Modals/RejectErrorModal'
import AttachmentsModal from '../../PolicyErrors/AttachmentsModal'
import { CarrierErrorColumns } from './CarrierErrorColumns'
import { USRCarrierErrrorHeader } from './USRCarrierErrorHeader'

interface CarrierErrorNotificationsProps {
  workItem: WorkItemDetailsModel
}

export const USRCarrierErrorNotification = ({ workItem }: CarrierErrorNotificationsProps) => {
  const [carrierError, setCarrierError] = useState<CarrierErrorNotification>(BlankGenericCarrierError)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const {
    isModalOpen: isAttachmentsModalOpen,
    openModal: openAttachmentsModal,
    closeModal: closeAttachmentsModal,
    currentAttachments: attachments,
  } = useAttachmentsModal()

  /* istanbul ignore next */
  const fetchCarrierErrors = async () => {
    getCarrierErrorNotifications(workItem.workQueue_WorkItemID)
      .then((data) => {
        setCarrierError(data)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  /* istanbul ignore next */
  const updateUSRCarrierErrors = async (request: UpdateErrorsModel) => {
    const result = await resolveUSRCarrierError(
      request.userAccepted,
      workItem.workQueue_WorkItemID,
      request.responseText
    )
    await fetchCarrierErrors()
    return result
  }

  /* istanbul ignore next */
  const {
    isModalOpen: isRejectModalOpen,
    openModal: openRejectModal,
    closeModal: closeRejectModal,
    rejectErrors,
  } = useErrorProcessingModal(updateUSRCarrierErrors)

  /* istanbul ignore next */
  const {
    isModalOpen: isAcceptModalOpen,
    openModal: openAcceptModal,
    closeModal: closeAcceptModal,
    acceptErrors,
  } = useErrorProcessingModal(updateUSRCarrierErrors)

  /* istanbul ignore next */
  useEffect(() => {
    if (workItem.workQueue_WorkItemID?.length > 0) {
      fetchCarrierErrors()
    }
    //eslint-disable-next-line
  }, [workItem.workQueue_WorkItemID])
  const error = carrierError!.error
  return (
    <PhoenixBaseCard contentPadded cardTitle="Carrier Error Notification">
      <PhoenixBaseCard isLoading={isLoading} cardTitle="USR Error Details" variantType="Secondary">
        <USRCarrierErrrorHeader error={error} />
        <PhoenixBaseTable columns={CarrierErrorColumns} data={error.errors} />
        <Grid container>
          <Grid item xs={9}>
            <ResponseTimeline
              attachmentsFunc={function (rows: ErrorAttachment[]): void {
                openAttachmentsModal(rows)
              }}
              responses={carrierError!.communications}
            />
          </Grid>
          <Grid item xs={3}>
            {workItem.workItemStatus == 'Open' && (
              <Box sx={{ display: 'flex', gap: '0.5rem', mt: '22px' }}>
                <Button color="success" onClick={() => openAcceptModal([error.errors[0].editID])} variant="contained">
                  Accept
                </Button>
                <Button color="error" onClick={() => openRejectModal([error.errors[0].editID])} variant="contained">
                  Reject
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>
      </PhoenixBaseCard>

      <AcceptErrorModal
        isOpen={isAcceptModalOpen}
        onCancel={closeAcceptModal}
        onAccept={() => acceptErrors(workItem.workQueue_WorkItemID, 'unitstat')}
      />
      <RejectErrorModal
        isOpen={isRejectModalOpen}
        onCancel={closeRejectModal}
        onReject={(responseText: string) => rejectErrors(workItem.workQueue_WorkItemID, 'unitstat', responseText)}
      />
      <AttachmentsModal isOpen={isAttachmentsModalOpen} onCancel={closeAttachmentsModal} attachments={attachments} />
    </PhoenixBaseCard>
  )
}
