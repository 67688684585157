import { ErrorCommunication, ErrorDTO } from 'componix'
import { UUID } from 'crypto'

export interface CarrierErrorNotification {
  error: PolicyCarrierErrorResponseModel | UnitStatCarrierErrorResponseModel
  communications: ErrorCommunication[]
}

export interface PolicyCarrierErrorResponseModel {
  txnCode: string
  txnIssue: string
  noticeDate: string
  fineDueDate: string
  fineStatus: string
  fineStatusDate: string
  errorMessage: string
  editID: string
  status: string
  errors: ErrorDTO[]
  guid: UUID
}

export interface UnitStatCarrierErrorResponseModel {
  noticeDate: string
  fineDueDate: string
  fineStatus: string
  fineStatusDate: string
  errors: ErrorDTO[]
  guid: UUID
}

export const BlankUSRCarrierError: CarrierErrorNotification = {
  error: {
    noticeDate: '',
    fineDueDate: '',
    fineStatus: '',
    fineStatusDate: '',
    errors: [],
    guid: '00000000-0000-0000-0000-000000000000',
  },
  communications: [],
}

export const BlankPolicyCarrierError: CarrierErrorNotification = {
  error: {
    txnCode: '',
    txnIssue: '',
    noticeDate: '',
    fineDueDate: '',
    fineStatus: '',
    fineStatusDate: '',
    errorMessage: '',
    editID: '',
    status: '',
    errors: [],
    guid: '00000000-0000-0000-0000-000000000000',
  },
  communications: [],
}

export const BlankGenericCarrierError: CarrierErrorNotification = {
  error: {
    noticeDate: '',
    fineDueDate: '',
    fineStatus: '',
    fineStatusDate: '',
    errors: [],
    guid: '00000000-0000-0000-0000-000000000000',
  },
  communications: [],
}
