import { useQuery } from '@tanstack/react-query'
import { PolicyErrorResponseModel } from '../models/PolicyDetails/PolicyErrorResponseModel'
import { UnitStatDeductibleModel } from '../models/UnitStatDeductibleModel'
import UnitStatDetailsModel from '../models/UnitStatDetailsModel'
import UnitStatExposuresResponse from '../models/UnitStatExposuresModel'
import { UnitStatLossRecordsModel } from '../models/UnitStatLossRecordsModels'
import UnitStatPolicyConditionsModel from '../models/UnitStatPolicyConditionsModel'
import UnitStatPolicyInformationModel from '../models/UnitStatPolicyInfoModel'
import { UnitStatPolicyTypeModel } from '../models/UnitStatPolicyTypeModel'
import {
  getUnitStatDeductible,
  getUnitStatDetails,
  getUnitStatErrors,
  getUnitStatExposures,
  getUnitStatLossRecords,
  getUnitStatPolicyConditions,
  getUnitStatPolicyInfo,
  getUnitStatPolicyType,
} from '../services/unitStatService'
import { CacheKeys } from './cacheKeys'
import { StaleTime } from './staleTime'

const useGetUnitStatDetails = (unitStatId: string) =>
  useQuery<UnitStatDetailsModel>({
    queryKey: [CacheKeys.UnitStatDetails, unitStatId],
    queryFn: () => getUnitStatDetails(unitStatId),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: !!unitStatId,
  })

const useGetUnitStatPolicyInfo = (unitStatId: string) =>
  useQuery<UnitStatPolicyInformationModel>({
    queryKey: [CacheKeys.UnitStatPolicyInfo, unitStatId],
    queryFn: () => getUnitStatPolicyInfo(unitStatId),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: !!unitStatId,
  })

const useGetUnitStatPolicyConditions = (unitStatId: string) =>
  useQuery<UnitStatPolicyConditionsModel>({
    queryKey: [CacheKeys.UnitStatPolicyConditions, unitStatId],
    queryFn: () => getUnitStatPolicyConditions(unitStatId),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: !!unitStatId,
  })

const useGetUnitStatPolicyType = (unitStatId: string) =>
  useQuery<UnitStatPolicyTypeModel>({
    queryKey: [CacheKeys.UnitStatPolicyType, unitStatId],
    queryFn: () => getUnitStatPolicyType(unitStatId),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: false,
  })

const useGetUnitStatDeductible = (unitStatId: string) =>
  useQuery<UnitStatDeductibleModel>({
    queryKey: [CacheKeys.UnitStatDeductible, unitStatId],
    queryFn: () => getUnitStatDeductible(unitStatId),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: false,
  })

const useGetUnitStatExposures = (unitStatId: string) =>
  useQuery<UnitStatExposuresResponse>({
    queryKey: [CacheKeys.UnitStatExposures, unitStatId],
    queryFn: () => getUnitStatExposures(unitStatId),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: false,
  })

const useGetUnitStatLossRecords = (unitStatId: string) =>
  useQuery<UnitStatLossRecordsModel>({
    queryKey: [CacheKeys.UnitStatLossRecords, unitStatId],
    queryFn: () => getUnitStatLossRecords(unitStatId),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: false,
  })

const useGetUnitStatErrors = (unitStatId: string) =>
  useQuery<PolicyErrorResponseModel>({
    queryKey: [CacheKeys.UnitStatErrors, unitStatId],
    queryFn: () => getUnitStatErrors(unitStatId),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: false,
  })

export {
  useGetUnitStatErrors,
  useGetUnitStatLossRecords,
  useGetUnitStatExposures,
  useGetUnitStatDeductible,
  useGetUnitStatPolicyType,
  useGetUnitStatPolicyConditions,
  useGetUnitStatPolicyInfo,
  useGetUnitStatDetails,
}
