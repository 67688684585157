import { useQuery } from '@tanstack/react-query'
import MyWorkQueueDetailsModel from '../models/MyWorkQueueDetailsModel'
import { WorkQueueItemModel } from '../models/WorkQueueItemModel'
import { getClosedWorkQueueItems, getMyWorkQueueDetails, getOpenWorkQueueItems } from '../services/workQueueService'
import { CacheKeys } from './cacheKeys'

const useGetOpenWorkItems = () =>
  useQuery<WorkQueueItemModel[]>({
    queryKey: [CacheKeys.OpenWorkQueueItems],
    queryFn: () => getOpenWorkQueueItems(),
    // TODO: Add stale time when we have caching fully hooked up in work queue
  })
const useGetClosedWorkItems = () =>
  useQuery<WorkQueueItemModel[]>({
    queryKey: [CacheKeys.ClosedWorkQueueItems],
    queryFn: () => getClosedWorkQueueItems(),
    // TODO: Add stale time when we have caching fully hooked up in work queue
  })
const useGetWorkQueueDetails = () =>
  useQuery<MyWorkQueueDetailsModel>({
    queryKey: [CacheKeys.GetMyWorkQueueDetails],
    queryFn: () => getMyWorkQueueDetails(),
    // TODO: Add stale time when we have caching fully hooked up in work queue
  })
export { useGetWorkQueueDetails, useGetClosedWorkItems, useGetOpenWorkItems }
