import { NoResultsFound, PhoenixBaseCard } from 'componix'
import { UUID } from 'crypto'
import { useGetPolicyErrors } from '../../cache/policyCache'
import useAttachmentsModal from '../../hooks/useAttachmentsModal'
import useErrorProcessingModal from '../../hooks/useErrorProcessingModal'
import useSetAlertDetails from '../../hooks/workQueue/useSetAlertDetails'
import { UpdateErrorsModel } from '../../models/PolicyDetails/UpdateErrorsModel'
import { updatePolicyErrors } from '../../services/policyService'
import AcceptErrorModal from '../Modals/AcceptErrorModal'
import RejectErrorModal from '../Modals/RejectErrorModal'
import AttachmentsModal from './AttachmentsModal'
import { TransactionCard } from './TransactionCard'

export interface PolicyErrorProps {
  policyID: UUID
}

const PolicyErrors = ({ policyID }: PolicyErrorProps) => {
  const {
    data: policyErrorsData,
    isLoading: isPolicyErrorsDataLoading,
    isError: isPolicyErrorsDataError,
    refetch: refetchPolicyErrorsData,
  } = useGetPolicyErrors(policyID)

  const updateErrors = async (request: UpdateErrorsModel) => {
    const result = updatePolicyErrors(policyID, request)
    await refetchPolicyErrorsData()
    return result
  }

  const {
    isModalOpen: isAcceptModalOpen,
    openModal: openAcceptModal,
    closeModal: closeAcceptModal,
    acceptErrors,
    isCallLoading: isAcceptCallLoading,
  } = useErrorProcessingModal(updateErrors)

  const {
    isModalOpen: isRejectModalOpen,
    openModal: openRejectModal,
    closeModal: closeRejectModal,
    rejectErrors,
    isCallLoading: isRejectCallLoading,
  } = useErrorProcessingModal(updateErrors)

  const {
    isModalOpen: isAttachmentsModalOpen,
    openModal: openAttachmentsModal,
    closeModal: closeAttachmentsModal,
    currentAttachments: attachments,
  } = useAttachmentsModal()

  useSetAlertDetails([isPolicyErrorsDataError])

  const isTransactionLoading = isPolicyErrorsDataLoading || isAcceptCallLoading || isRejectCallLoading

  return (
    <>
      <PhoenixBaseCard
        cardTitle={'Policy Errors'}
        contentPadded
        collapsible
        onExpand={refetchPolicyErrorsData}
        isLoading={isPolicyErrorsDataLoading}
      >
        {policyErrorsData?.length ? (
          policyErrorsData?.map((error) => (
            <TransactionCard
              key={error.guid}
              error={error}
              isLoading={isTransactionLoading}
              openAcceptModal={openAcceptModal}
              openRejectModal={openRejectModal}
              openAttachments={openAttachmentsModal}
            />
          ))
        ) : (
          <NoResultsFound />
        )}
      </PhoenixBaseCard>
      <AcceptErrorModal
        isOpen={isAcceptModalOpen}
        onCancel={closeAcceptModal}
        onAccept={() => acceptErrors(policyID, 'policy')}
      />
      <RejectErrorModal
        isOpen={isRejectModalOpen}
        onCancel={closeRejectModal}
        onReject={(responseText: string) => rejectErrors(policyID, 'policy', responseText)}
      />
      <AttachmentsModal isOpen={isAttachmentsModalOpen} onCancel={closeAttachmentsModal} attachments={attachments} />
    </>
  )
}

export default PolicyErrors
