import TabList from '@mui/lab/TabList'
import { Box, Button, DialogActions, Stack, Tab, Typography } from '@mui/material'
import { PhoenixBaseTable, PhoenixLink } from 'componix'
import { MRT_ColumnDef, MRT_Row } from 'material-react-table'
import { SyntheticEvent, useState } from 'react'
import { useAlertContext } from '../../contexts/AlertContext'
import { WorkQueueItemModel } from '../../models/WorkQueueItemModel'
import { workItemsManualClose, workItemsReassignUser } from '../../services/workItemService'
import { AlertSeverity } from '../AlertNotification/AlertNotification'
import ManualCloseModal from '../Modals/ManualCloseModal'
import WorkItemReassignModal from '../Modals/WorkItemReassignModal'

export const WorkQueueColumns = (isOpen: boolean): MRT_ColumnDef<WorkQueueItemModel>[] => {
  /* eslint react/prop-types: 0 */
  const lastColumn = isOpen
    ? {
        accessorKey: 'dueDate',
        header: 'Due Date',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        Cell: ({ row }) => {
          return (
            <Typography {...(row.original.isPastDue && { color: 'error' })} fontSize={14}>
              <>{row.original.dueDate}</>
            </Typography>
          )
        },
      }
    : {
        accessorKey: 'closedDate',
        header: 'Close Date',
      }

  return [
    {
      accessorKey: 'workItemNumber',
      header: 'Work Item Number',
      Cell: ({ row }) => {
        const { workItemType, workItemID, workItemNumber } = row.original
        let workItemPath
        switch (workItemType) {
          case 'Unmatched Transaction':
            workItemPath = `unmatched-policies`
            break
          case 'Carrier Error Notification - USR':
            workItemPath = `carrier-errors`
            break
          case 'Carrier Error Notification - Policy':
            workItemPath = 'carrier-errors'
            break
          default:
            break
        }
        return <PhoenixLink to={`${workItemPath}/${workItemID}`} value={workItemNumber} />
      },
    },
    {
      accessorKey: 'workItemType',
      header: 'Work Type',
    },
    {
      accessorKey: 'assignedBy',
      header: 'Assigned By',
    },
    {
      accessorKey: 'assignedDate',
      header: 'Assigned Date',
    },
    lastColumn,
  ]
}

interface WorkQueueTableProps {
  data: WorkQueueItemModel[]
  isLoading: boolean
  handleTabChange: (_: SyntheticEvent, newValue: string) => void
  isOpenTable: boolean
}

const WorkQueueTable = ({ data, isLoading, handleTabChange, isOpenTable }: WorkQueueTableProps) => {
  const [isManualCloseModalOpen, setIsManualCloseModalOpen] = useState(false)
  const [isWorkItemReassignModalOpen, setIsWorkItemReassignModal] = useState(false)
  const [selectedWorkItems, setSelectedWorkItems] = useState<string[]>([])
  const { setDetails: setAlertDetails } = useAlertContext()

  //================================================================================================
  const handleonManualClose = async (responseText: string) => {
    await workItemsManualClose(selectedWorkItems, responseText)
      .then(() => setAlertDetails({ message: 'Work Item closed successfully.', severity: AlertSeverity.SUCCESS }))
      .catch(() =>
        setAlertDetails({ message: 'Work Item cannot be closed. Please try again.', severity: AlertSeverity.ERROR })
      )
  }

  //================================================================================================
  /* istanbul ignore next */
  const handleReassign = async (userEmail: string) => {
    await workItemsReassignUser(selectedWorkItems, userEmail)
      .then(() => setAlertDetails({ message: 'Work Item reassigned successfully.', severity: AlertSeverity.SUCCESS }))
      .catch(() => setAlertDetails({ message: 'Work Item Failed To Reassign.', severity: AlertSeverity.ERROR }))
  }

  //================================================================================================
  /* istanbul ignore next */
  const reassignCloseModal = () => {
    setIsWorkItemReassignModal(false)
  }

  //================================================================================================
  /* istanbul ignore next */
  const closeManualCloseModal = () => {
    setIsManualCloseModalOpen(false)
  }

  return (
    <Stack>
      <Box
        // Custom styling for the toolbar actions and it's parent
        sx={{
          '.MuiBox-root:has(.toolbar-custom-actions)': {
            flexWrap: 'wrap',
            gap: 0,
            '& > .MuiBox-root': {
              flexGrow: 1,
              padding: 0,
              '& > .MuiBox-root:last-child': {
                background: '#EEF0F1',
                paddingTop: 0.5,
                paddingRight: 1,
              },
            },
          },
        }}
      >
        <PhoenixBaseTable
          columns={WorkQueueColumns(isOpenTable)}
          data={data}
          isLoading={isLoading}
          showTableWithoutData
          enableRowSelection
          showPagination
          overrides={{
            ...(isOpenTable && { enableRowSelection: true }),
            enableFilters: true,
            enableTopToolbar: true,
            positionToolbarAlertBanner: 'none',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            renderTopToolbarCustomActions: ({ table }: any) => {
              const handleReassign = () => {
                const rows: string[] = []
                table.getSelectedRowModel().flatRows.forEach((row: MRT_Row<WorkQueueItemModel>) => {
                  rows.push(row.original.workItemID)
                })

                setSelectedWorkItems(rows)
                setIsWorkItemReassignModal(true)
              }

              const handleClose = () => {
                const rows: string[] = []
                table.getSelectedRowModel().flatRows.forEach((row: MRT_Row<WorkQueueItemModel>) => {
                  rows.push(row.original.workItemID)
                })
                setSelectedWorkItems(rows)
                setIsManualCloseModalOpen(true)
              }

              const noRowsSelected = !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
              return (
                <Box
                  display="flex"
                  justifyContent={'space-between'}
                  flexGrow={1}
                  width={'100%'}
                  className={'toolbar-custom-actions'}
                >
                  <>
                    {/* TabList should be a sibling of TabPanels, but down here to override styling and match designs */}
                    <TabList onChange={handleTabChange}>
                      <Tab label={'Open'} value={'0'}></Tab>
                      <Tab label={'Closed'} value={'1'}></Tab>
                    </TabList>
                  </>
                  {isOpenTable && (
                    <>
                      <DialogActions>
                        <Button size="small" variant="contained" disabled={noRowsSelected} onClick={handleReassign}>
                          Reassign
                        </Button>
                        <Button size="small" variant="contained" disabled={noRowsSelected} onClick={handleClose}>
                          Manually Close
                        </Button>
                      </DialogActions>
                    </>
                  )}
                </Box>
              )
            },
          }}
        />
      </Box>

      <ManualCloseModal
        isOpen={isManualCloseModalOpen}
        onCancel={closeManualCloseModal}
        onManualClose={(responseText: string) => handleonManualClose(responseText)}
      />

      <WorkItemReassignModal
        isOpen={isWorkItemReassignModalOpen}
        onCancel={reassignCloseModal}
        onReassign={(userEmail: string) => handleReassign(userEmail)}
      />
    </Stack>
  )
}

export default WorkQueueTable
