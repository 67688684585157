import { useQuery } from '@tanstack/react-query'
import { Combo } from '../models/Combo'
import { ComboIdResponse } from '../models/ComboIdResponse'
import { RatingHistoryDTO } from '../models/RatingHistoryDTO'
import { getComboInfo, getRatingHistory } from '../services/comboService'
import { getComboDataByCoverageId } from '../services/coverageService'
import { CacheKeys } from './cacheKeys'
import { StaleTime } from './staleTime'

const useGetRatingHistory = (comboId: string) => {
  return useQuery<RatingHistoryDTO[]>({
    queryKey: [CacheKeys.RatingHistory, comboId],
    queryFn: () => getRatingHistory(comboId),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: !!comboId,
  })
}

const useGetComboInfo = (comboId: string) => {
  return useQuery<Combo>({
    queryKey: [CacheKeys.ComboInfo, comboId],
    queryFn: () => getComboInfo(comboId),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: !!comboId,
  })
}

const useGetComboDataByCoverageId = (coverageId: string) => {
  return useQuery<ComboIdResponse>({
    queryKey: [CacheKeys.ComboDataByCoverageId, coverageId],
    queryFn: () => getComboDataByCoverageId(coverageId),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: !!coverageId,
  })
}

export { useGetRatingHistory, useGetComboInfo, useGetComboDataByCoverageId }
