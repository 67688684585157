import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import { useEffect, useState } from 'react'
import React from 'react'
import { useAlertContext } from '../../contexts/AlertContext'

export enum AlertSeverity {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
}

export const AlertNotification = () => {
  const { details, setDetails } = useAlertContext()
  const [open, setOpen] = useState(false)
  useEffect(() => {
    setOpen(details.message.length > 0)
  }, [details])

  /* istanbul ignore next */
  const handleClose = () => {
    setOpen(false)
    setDetails({ message: '', severity: AlertSeverity.ERROR })
  }

  return (
    <Snackbar
      open={open}
      autoHideDuration={10000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      style={{ position: 'fixed', top: '80px' }}
    >
      <Alert
        id="alert"
        data-testid="alert"
        elevation={6}
        variant="filled"
        onClose={handleClose}
        severity={details.severity}
      >
        {details.message}
      </Alert>
    </Snackbar>
  )
}
