import TabContext from '@mui/lab/TabContext'
import TabPanel from '@mui/lab/TabPanel'
import { Box } from '@mui/material'
import { useEffect } from 'react'
import { useGetClosedWorkItems, useGetOpenWorkItems } from '../../cache/workQueueCache'
import { useAlertContext } from '../../contexts/AlertContext'
import { useTabs } from '../../hooks/useTabs'
import { AlertSeverity } from '../AlertNotification/AlertNotification'
import WorkQueueTable from './WorkQueueTable'

const WorkQueueTabs = () => {
  const { tabIndex, handleTabChange } = useTabs()
  const { setDetails: setAlertDetails } = useAlertContext()

  const { isPending: isOpenItemsLoading, error: openItemsError, data: openItems } = useGetOpenWorkItems()
  const { isPending: isClosedItemsLoading, error: closedItemsError, data: closedItems } = useGetClosedWorkItems()

  useEffect(() => {
    if (openItemsError || closedItemsError) {
      setAlertDetails({
        message: 'Please refresh the page and try again.',
        severity: AlertSeverity.ERROR,
      })
    }
  })

  return (
    <Box>
      <TabContext value={tabIndex}>
        {/* TabList should generally live here, but inside WorkQueue table to override styling */}
        <TabPanel value="0" sx={{ padding: 0 }}>
          <WorkQueueTable
            data={openItems ?? []}
            isLoading={isOpenItemsLoading}
            handleTabChange={handleTabChange}
            isOpenTable
          />
        </TabPanel>
        <TabPanel value="1" sx={{ padding: 0 }}>
          <WorkQueueTable
            data={closedItems ?? []}
            isLoading={isClosedItemsLoading}
            handleTabChange={handleTabChange}
            isOpenTable={false}
          />
        </TabPanel>
      </TabContext>
    </Box>
  )
}

export default WorkQueueTabs
