import { Grid } from '@mui/material'
import { FieldGroup } from 'componix'
import { PolicyCarrierErrorResponseModel } from '../../../models/WorkItemDetails/CarrierErrorNotification'

interface PolicyCarrierErrorHeaderProps {
  error: PolicyCarrierErrorResponseModel
}

export const PolicyCarrierErrorHeader = ({ error }: PolicyCarrierErrorHeaderProps) => {
  return (
    <Grid container p={2}>
      <Grid item xs={2}>
        <FieldGroup label={'Txn Code'} value={error.txnCode} />
      </Grid>
      <Grid item xs={2}>
        <FieldGroup label={'Txn Issue Date'} value={error.txnIssue} />
      </Grid>
      <Grid item xs={2}>
        <FieldGroup label={'Notice Date'} value={error.noticeDate} />
      </Grid>
      <Grid item xs={2}>
        <FieldGroup label={'Fine Due Date'} value={error.fineDueDate} />
      </Grid>
      <Grid item xs={2}>
        <FieldGroup label={'Fine Status'} value={error.fineStatus} />
      </Grid>
      <Grid item xs={2}>
        <FieldGroup label={'Fine Status Date'} value={error.fineStatusDate} />
      </Grid>
    </Grid>
  )
}
