import {
  BreadcrumbBar,
  DataFieldBlankValue,
  PhoenixBaseCard,
  PhoenixLink,
  StatusBadge,
  TableWithSubrows,
  TableWithSubrowsProps,
} from 'componix'
import { UUID } from 'crypto'
import { MRT_ColumnDef } from 'material-react-table'
import React from 'react'
import { useParams } from 'react-router-dom'
import { useGetComboInfo, useGetRatingHistory } from '../cache/comboCache'
import PrimaryCoverageIdentifier from '../components/GroupIdentifier'
import LayoutWithSideRail from '../components/LayoutWithSideRail'
import { useNavbar } from '../contexts/NavbarContext'
import useSetAlertDetails from '../hooks/workQueue/useSetAlertDetails'
import { RatingHistoryDTO } from '../models/RatingHistoryDTO'
import { booleanToYN } from '../utils/booleanToYN'

const Combo: React.FC = () => {
  const { comboId } = useParams()
  const { marginLeft } = useNavbar()
  const {
    data: ratingHistoryData,
    isPending: isRatingHistoryLoading,
    isError: isRatingHistoryError,
  } = useGetRatingHistory(comboId as UUID)

  const {
    data: comboInfoData,
    isPending: isComboInfoLoading,
    isError: isComboInfoError,
  } = useGetComboInfo(comboId as UUID)

  /* eslint react/prop-types: 0 */ /* istanbul ignore next */
  const columns: MRT_ColumnDef<RatingHistoryDTO>[] = [
    { accessorKey: 'effectiveDate', size: 150, header: 'RATING EFFECTIVE DATE' },
    { accessorKey: 'revisionNumber', header: 'REVISION #', size: 20 },
    {
      accessorKey: 'hasInterstateID',
      header: 'INTERSTATE ID INDICATOR',
      Cell: ({ cell }) => booleanToYN(cell.getValue<boolean>()),
    },
    {
      accessorKey: 'modFactor',
      header: 'MOD FACTOR',
      Cell: ({ row, cell }) =>
        cell.getValue() ? (
          <PhoenixLink to={`/ratings/${row.original.ratingID}`} value={row.original.modFactor.toString()} />
        ) : (
          <DataFieldBlankValue />
        ),
    },
    {
      accessorKey: 'transitionalModCap',
      header: 'TRANSITIONAL MOD CAP',
      Cell: ({ cell }) => booleanToYN(cell.getValue<boolean>()),
    },
    { accessorKey: 'result', header: 'RESULT' },
    {
      accessorKey: 'status',
      header: 'STATUS',
      Cell: ({ cell }) => <StatusBadge description={cell.getValue<string>()} />,
    },
    { accessorKey: 'statusDate', header: 'STATUS DATE' },
    { accessorKey: 'statusBy', header: 'STATUS BY' },
  ]
  const navMenuOptions: TableWithSubrowsProps<RatingHistoryDTO>['kebabMenuOptions'] = [
    {
      label: 'View Rating Details',
      to: ({ row }) => `/ratings/${row.original.ratingID}`,
    },
  ]

  useSetAlertDetails([isRatingHistoryError, isComboInfoError])

  return (
    <div style={{ transition: 'margin-left 0.2s', marginLeft: marginLeft, marginRight: '24px', marginTop: '80px' }}>
      <BreadcrumbBar
        crumbs={[
          {
            label: `Combo ${comboInfoData?.comboId}`,
            to: `/combos/${comboInfoData?.comboGlobalId}`,
          },
        ]}
      />
      <LayoutWithSideRail
        mainContent={
          <div>
            <PhoenixBaseCard cardTitle={'Rating History'}>
              <TableWithSubrows
                columns={columns}
                data={ratingHistoryData ?? []}
                isLoading={isRatingHistoryLoading}
                kebabColumnHeader=""
                kebabMenuOptions={navMenuOptions}
              />
            </PhoenixBaseCard>
          </div>
        }
        sideRailComponentArray={[
          <PrimaryCoverageIdentifier
            key={''}
            title="Combo Group"
            combo={{ identifier: String(comboInfoData?.comboId), guid: comboInfoData?.comboGlobalId }}
            isLoading={isComboInfoLoading}
          />,
        ]}
      ></LayoutWithSideRail>
    </div>
  )
}

export default Combo
